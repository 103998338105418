import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';

function TransactionModal({ open, onClose, onAddTransaction }) {
  const [amount, setAmount] = useState('');
  const [type, setType] = useState('Deposit');

  const handleSubmit = async () => {
    const isoDate = new Date().toISOString();
    const date = new Date(isoDate);

    const friendlyDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const newTransaction = {
      id: Date.now(),
      date: friendlyDate,
      type,
      amount: parseFloat(amount),
    };

    // Send the transaction to the server
    await saveTransactionToServer(newTransaction);

    // Call the onAddTransaction to update the UI
    onAddTransaction(newTransaction);
    onClose();
  };

  const saveTransactionToServer = async (transaction) => {
    try {
      const response = await fetch('/api/transactions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*',
        },
        body: JSON.stringify(transaction),
      });
      if (!response.ok) {
        throw new Error('Failed to save transaction');
      }
      console.log('Transaction saved successfully');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Operation</DialogTitle>
      <DialogContent>
        <TextField
          type="number"
          label="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Select
          value={type}
          onChange={(e) => setType(e.target.value)}
          fullWidth
          margin="normal"
        >
          <MenuItem value="Deposit">Deposit</MenuItem>
          <MenuItem value="Withdrawal">Withdrawal</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TransactionModal;
